import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://a25f919aaf644c84b0b03b361951fd34@o1044920.ingest.sentry.io/6020169",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});